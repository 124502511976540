<template>
  <div>
    <!--面包屑-->
    <div class="breadcrumb">
      <el-button @click="$router.go(-1)">返回</el-button>
    </div>
    <!--主体内容-->
    <div>
      <el-form label-width="120px" label-position="right">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="content bgFFF">
              <div class="title">系统信息</div>
              <el-form-item label="商户ID:">
                {{ formInline.operationId }}
              </el-form-item>
              <el-form-item label="商户编码:">{{ formInline.operationCode }}</el-form-item>
              <el-form-item label="状态:">
                <span v-if="formInline.operationState === 1" style="color: #67c23a">已启用</span>
                <span v-else style="color: red">已停用</span>
              </el-form-item>
              <el-form-item label="商户名称:">{{ formInline.operationName }}</el-form-item>
              <el-form-item label="商户类型:">{{ formInline.operationTypeName }}</el-form-item>
              <el-form-item label="经营业务类型:">{{ formInline.businessTypeName }}</el-form-item>
              <el-form-item label="商户有效期:">
                {{ formInline.startTime | timeFilter }} ~ {{ formInline.endTime | timeFilter }}
              </el-form-item>
            </div>
            <div class="content bgFFF">
              <div class="title">工商信息</div>
              <el-form-item label="公司名称:">{{ formInline.operationFullName }}</el-form-item>
              <el-form-item label="公司地址:">{{ formInline.operationAddress }}</el-form-item>
              <el-form-item label="联系人:">{{ formInline.contact }}</el-form-item>
              <el-form-item label="联系电话:">{{ formInline.mobile }}</el-form-item>
              <el-form-item label="邮箱:">{{ formInline.email }}</el-form-item>
              <el-form-item label="营业执照:" v-if="url">
                <div class="demo-image__preview">
                  <el-image 
                    style="width: 100px; height: 100px"
                    :src="url" 
                    :preview-src-list="srcList">
                  </el-image>
                </div>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="content bgFFF">
              <div class="title">账户信息</div>
              <el-form-item label="开户行名称:">{{ formInline.bankName }}</el-form-item>
              <el-form-item label="开户行地址:">{{ formInline.bankAddress }}</el-form-item>
              <el-form-item label="对公账户号:">{{ formInline.bankCard }}</el-form-item>
            </div>
            <div class="content bgFFF">
              <div class="title">清结算信息</div>
              <el-form-item label="支付收款帐户:">{{ formInline.accountTypeName }}</el-form-item>
              <template v-if="formInline.accountType === 1">
                <el-form-item label="清分比例（%）:">{{ formInline.clearProportion }}</el-form-item>
                <el-form-item label="清分周期（T+）:">{{ formInline.clearCycle }}</el-form-item>
                <el-form-item label="结算费率（%）:">{{ formInline.settlementRate }}</el-form-item>
                <el-form-item label="手续费承担方:">{{ formInline.bearSide === 1 ? '商户' : '爱泊车' }}</el-form-item>
                <el-form-item label="结算周期（T+）:">{{ formInline.settlementCycle }}</el-form-item>
              </template>
              <template v-if="formInline.accountType === 2">
                <el-row class="config-row">
                  <el-col :span="12">APP (微信支付)</el-col>
                  <el-col :span="12">
                    <el-button type="primary" size="mini" @click="checkConfig(1)">查看配置</el-button>
                  </el-col>
                </el-row>
                <el-row class="config-row">
                  <el-col :span="12">APP (支付宝支付)</el-col>
                  <el-col :span="12">
                    <el-button type="primary" size="mini" @click="checkConfig(2)">查看配置</el-button>
                  </el-col>
                </el-row>
                <el-row class="config-row">
                  <el-col :span="12">微信公众号及小程序</el-col>
                  <el-col :span="12">
                    <el-button type="primary" size="mini" @click="checkConfig(3)">查看配置</el-button>
                  </el-col>
                </el-row>
                <el-row class="config-row">
                  <el-col :span="12">支付宝生活号及小程序</el-col>
                  <el-col :span="12">
                    <el-button type="primary" size="mini" @click="checkConfig(4)">查看配置</el-button>
                  </el-col>
                </el-row>
              </template>
              <template v-if="formInline.accountType === 3">
                <el-row class="config-row">
                  <el-col :span="12">APP (微信支付)</el-col>
                  <el-col :span="12">
                    <el-button type="primary" size="mini" @click="checkConfig(5)">查看配置</el-button>
                  </el-col>
                </el-row>
                <el-row class="config-row">
                  <el-col :span="12">APP (支付宝支付)</el-col>
                  <el-col :span="12">
                    <el-button type="primary" size="mini" @click="checkConfig(6)">查看配置</el-button>
                  </el-col>
                </el-row>
                <el-row class="config-row">
                  <el-col :span="12">微信公众号及小程序</el-col>
                  <el-col :span="12">
                    <el-button type="primary" size="mini" @click="checkConfig(7)">查看配置</el-button>
                  </el-col>
                </el-row>
                <el-row class="config-row">
                  <el-col :span="12">支付宝生活号及小程序</el-col>
                  <el-col :span="12">
                    <el-button type="primary" size="mini" @click="checkConfig(8)">查看配置</el-button>
                  </el-col>
                </el-row>
              </template>
              <el-dialog :visible.sync="dialogVisible">
                <div slot="title" class="slot-title">{{ slotTitle }}</div>
                <el-form label-width="180px" label-position="right">
                  <div v-if="slotType === 1">
                    <el-form-item label="微信子商户号:">{{ formInline.wxMchIdByApp }}</el-form-item>
                  </div>
                  <div v-if="slotType === 2">
                    <el-form-item label="支付宝应用ID:">{{ formInline.aliApplicationId }}</el-form-item>
                    <el-form-item label="支付宝支付商户ID:">{{ formInline.aliThirdId }}</el-form-item>
                    <el-form-item label="支付宝支付商户私钥:">
                      <span class="copy-btn" v-clipboard:copy="formInline.aliThirdIdKey" v-clipboard:success="onCopy" v-clipboard:error="onError">
                        复制 <i class="el-icon-document-copy"/>
                      </span>
                    </el-form-item>
                    <el-form-item label="支付宝公钥:">
                      <span class="copy-btn" v-clipboard:copy="formInline.aliApiKey" v-clipboard:success="onCopy" v-clipboard:error="onError">
                        复制 <i class="el-icon-document-copy"/>
                      </span>
                    </el-form-item>
                  </div>
                  <div v-if="slotType === 3">
                    <el-form-item label="微信子商户号:">{{ formInline.wxMchIdByMicro }}</el-form-item>
                  </div>
                  <div v-if="slotType === 4">
                    <el-form-item label="支付宝应用ID:">{{ formInline.aliMicrApplicationId }}</el-form-item>
                    <el-form-item label="支付宝支付商户ID:">{{ formInline.aliMicrThirdId }}</el-form-item>
                    <el-form-item label="支付宝支付商户私钥:">
                      <span class="copy-btn" v-clipboard:copy="formInline.aliMicrThirdIdKey" v-clipboard:success="onCopy" v-clipboard:error="onError">
                        复制 <i class="el-icon-document-copy"/>
                      </span>
                    </el-form-item>
                    <el-form-item label="支付宝公钥:">
                      <span class="copy-btn" v-clipboard:copy="formInline.aliMicrApiKey" v-clipboard:success="onCopy" v-clipboard:error="onError">
                        复制 <i class="el-icon-document-copy"/>
                      </span>
                    </el-form-item>
                  </div>
                  <div v-if="slotType === 5">
                    <el-form-item label="微信支付应用ID(开放平台):">{{ formInline.wxApplicationId }}</el-form-item>
                    <el-form-item label="微信支付商户ID:">{{ formInline.wxThirdId }}</el-form-item>
                    <el-form-item label="微信支付APL_KEY:">
                      <span class="copy-btn" v-clipboard:copy="formInline.wxApiKey" v-clipboard:success="onCopy" v-clipboard:error="onError">
                        复制 <i class="el-icon-document-copy"/>
                      </span>
                    </el-form-item>
                    <el-form-item label="微信支付开发者密钥:">
                      <span class="copy-btn" v-clipboard:copy="formInline.wxDeveKey" v-clipboard:success="onCopy" v-clipboard:error="onError">
                        复制 <i class="el-icon-document-copy"/>
                      </span>
                    </el-form-item>
                  </div>
                  <div v-if="slotType === 6">
                    <el-form-item label="支付宝应用ID:">{{ formInline.aliApplicationId }}</el-form-item>
                    <el-form-item label="支付宝支付商户ID:">{{ formInline.aliThirdId }}</el-form-item>
                    <el-form-item label="支付宝支付商户私钥:">
                      <span class="copy-btn" v-clipboard:copy="formInline.aliThirdIdKey" v-clipboard:success="onCopy" v-clipboard:error="onError">
                        复制 <i class="el-icon-document-copy"/>
                      </span>
                    </el-form-item>
                    <el-form-item label="支付宝公钥:" prop="aliApiKey">
                      <span class="copy-btn" v-clipboard:copy="formInline.aliApiKey" v-clipboard:success="onCopy" v-clipboard:error="onError">
                        复制 <i class="el-icon-document-copy"/>
                      </span>
                    </el-form-item>
                  </div>
                  <div v-if="slotType === 7">
                    <el-form-item label="微信支付应用ID(公众平台):">{{ formInline.wxMicroApplicationId }}</el-form-item>
                    <el-form-item label="微信支付商户ID:">{{ formInline.wxMicroThirdId }}</el-form-item>
                    <el-form-item label="微信支付APL_KEY:">
                      <span class="copy-btn" v-clipboard:copy="formInline.wxMicroApiKey" v-clipboard:success="onCopy" v-clipboard:error="onError">
                        复制 <i class="el-icon-document-copy"/>
                      </span>
                    </el-form-item>
                    <el-form-item label="微信支付开发者密钥:">
                      <span class="copy-btn" v-clipboard:copy="formInline.wxMicroDeveKey" v-clipboard:success="onCopy" v-clipboard:error="onError">
                        复制 <i class="el-icon-document-copy"/>
                      </span>
                    </el-form-item>
                  </div>
                  <div v-if="slotType === 8">
                    <el-form-item label="支付宝应用ID:">{{ formInline.aliMicrApplicationId }}</el-form-item>
                    <el-form-item label="支付宝支付商户ID:">{{ formInline.aliMicrThirdId }}</el-form-item>
                    <el-form-item label="支付宝支付商户私钥:">
                      <span class="copy-btn" v-clipboard:copy="formInline.aliMicrThirdIdKey" v-clipboard:success="onCopy" v-clipboard:error="onError">
                        复制 <i class="el-icon-document-copy"/>
                      </span>
                    </el-form-item>
                    <el-form-item label="支付宝公钥:">
                      <span class="copy-btn" v-clipboard:copy="formInline.aliMicrApiKey" v-clipboard:success="onCopy" v-clipboard:error="onError">
                        复制 <i class="el-icon-document-copy"/>
                      </span>
                    </el-form-item>
                  </div>
                </el-form>
                <span slot="footer" class="dialog-footer">
                  <el-button @click="dialogVisible = false">关闭</el-button>
                </span>
              </el-dialog>
            </div>
            <div class="content bgFFF">
              <div class="title">操作信息</div>
              <el-form-item label="最后操作人:">{{ formInline.operatorName }}</el-form-item>
              <el-form-item label="最后修改时间:">{{ formInline.updatedTime }}</el-form-item>
              <el-form-item label="初始创建时间:">{{ formInline.createdTime }}</el-form-item>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  filters: {
    timeFilter (val) {
      if (!val) return
      return val.split(' ')[0]
    }
  },
  data () {
    return {
      url: '',
      srcList: [],
      dialogVisible: false,
      slotTitle: '',
      slotType: '',
      operationId: this.$route.query.operationId,
      formInline: {}
    }
  },
  mounted () {
    this.getOperatorDetail()
    this.getImage()
  },
  methods: {
    getOperatorDetail () {
      const opt = {
        url: '/acb/2.0/operation/id/' + this.operationId,
        method: 'get',
        success: res => {
          const value = res.value
          this.setForm(value)
        }
      }
      this.$request(opt)
    },
    setForm (value) {
      const cleariInfo = value.cleariInfo ? JSON.parse(value.cleariInfo) : {}
  
      this.formInline = Object.assign(value, cleariInfo)
    },
    getImage () {
      const tempData = { commonKey: this.operationId }
      const opt = {
        url: '/acb/2.0/systems/loginUser/getImageList',
        method: 'post',
        data: tempData,
        success: res => {
          this.url = res.value[1]
          this.srcList.push(this.url)
        }
      }
      this.$request(opt)
    },
    checkConfig (type) {
      this.dialogVisible = true
      this.slotType = type
      const titles = ['APP（微信支付）', 'APP (支付宝支付)', '微信公众号及小程序', '支付宝生活号及小程序']
      this.slotTitle = titles[type - 1]
    },
    onCopy () {
      this.$alert('复制成功！', this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine')
      })
    },
    onError () {
      this.$alert('复制失败！', this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine')
      })
    }
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
  height 35px
  text-align right
  margin-bottom 20px
.content
  margin-bottom 20px
  padding 20px
  .title
    font-size 18px
    font-weight bold
    color #999
    padding-bottom 20px
>>>.el-icon-circle-close
  &:before
    font-size 40px
>>>.el-image-viewer__actions__inner
  i
    font-size 23px
>>>.el-image-viewer__btn
  i
    font-size 24px
.config-row
  text-align center
  line-height 4em
.slot-title
  font-size 16px
  font-weight bold
  text-align center
  color #606266
.copy-btn
  color #0f6eff
  cursor pointer
>>>.el-form-item__content
  word-break break-all
</style>
